@import 'variables';
@import 'header';
@import 'hammy';
@import 'footer';


@import 'landing';
@import 'testimonials';
@import 'howitworks';
@import 'featured';
@import 'getapp';
@import 'listen';
@import 'team';


body {
  font-family: $mainFont;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.45;
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $mainFont;
}

h2 {
  font-size: 33px;
}

main {
  margin-top: 120px;
  background-image: url(../images/background.jpg);
  position: relative;

  @media (max-width: 768px) {
      margin-top: 90px;
  }
}

.wrapper {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }
  
  1% {
    display: block;
    opacity: 0;
  }
  
  100% {
    display: block;
    opacity: 1;
  }
}

.back-to-top {
  animation: fadeInFromNone 0.5s ease-out;
  position: fixed;
    bottom: 20px;
    right: 20px;
    background: $secondaryColour;
    opacity: 0;
}

a {
  color: $black;
  text-decoration: none;
  text-transform: uppercase;
  transition: all .5s;
  outline: none;
  cursor: pointer;

  &:hover, &:focus, &:active {
    color: $mainColour;
  }
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

button {
  outline: none;
}


.hidden {
  position: absolute;
  left: -24000px;

  &:focus {
    left: 0;
  }
}

img {
  width: 100%;
  height: auto;
}