.featured {
  background: rgba(235, 235, 235, 0.25);
  padding: 70px 0;

  h2 {
    color: $mainColour;
    text-align: center;
    text-transform: uppercase;
  }

  .featured-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    img {
      max-width: 150px;
      height: auto;
      margin: 25px;
    }

    .ice-network {
      max-width: 250px;
    }

    .psa {
      max-width: 90px;
    }
  }
}