header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: white;
  box-shadow: 2px 2px 20px 1px rgba(0, 0, 0, .1);

  .top-banner {
    width: 100%;
    background: $secondaryColour;
    text-align: center;
    font-family: $mainFont;
    padding: 5px 0;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    p {
      margin: 0;
    }

  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 0;
    font-size: 0.9rem;

      @media (max-width: 768px) {
        justify-content: flex-start;
      }

      .logo {
        width: 300px;
        max-width: 440px;
        margin: 0 30px 0 20px;

        @media (max-width: 768px) {
            width: 250px;
            margin: 0;
        }
        img {
          width: 100%;
        }
      }


    .desktop-nav {
      display: flex;
        @media (max-width: 768px) {
            display: none;
        }
      li {
        margin-right: 10px;
        font-size: 0.8rem;
        a {
          cursor: pointer;
          font-family: $mainFont;
        }
      }
      li:last-of-type {
        margin-right: 0;
      }
    }
  }
}



