


/* Styling of overlay */
.bm-overlay {
  background: rgba(black, 0.1) !important;
  top: 0;
}

.bm-menu-wrap {
  top: 0;
}

.bm-burger-button {
  position: absolute;
  width: 31px;
  height: 25px;
  right: 5%;
  top: 55px;
  display: none;

  @media (max-width: 768px) {
      display: inline;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: black;
  height: 2px !important;
}


/* Individual item */
.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: white;
  transition: all 0.2s;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 5px;
  a {
    color: white;
  }

  &:hover, &:focus, &:active {
    background: transparent;
  }
}


/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: white;
  width: 1.5px !important;
}

/* General sidebar styles */
.bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1rem;
  background-image: url(../images/background.jpg);
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {

}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

.social-follow {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;

  li {
    margin-right: 15px;
    a {
      font-size: 1.3rem;
    }
  }
  li:last-of-type {
    margin: 0;
  }
}
