.listen {
  padding: 70px 0;

  h2 {
    text-align: center;
    font-size: 33px;
    color: $mainColour;
    text-transform: uppercase;
  }

  .information {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    
    @media (max-width: 900px) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }

    .section-copy {
      width: 50%;
      margin-right: 50px;

      @media (max-width: 900px) {
        margin: 0 auto;
        width: 90%;
      }

      h3 {
        color: white;
        font-size: 26px;

        @media (max-width: 900px) {
          text-align: center;
        }
      }

      ul{

        li {
          color: white;
          margin: 10px 0;
          font-size: 18px;
          span {
            color: lighten($mainColour, 5%);
          }

          &:before {
            content: "\2714"; 
            color: $secondaryColour; 
            font-weight: bold; 
            display: inline-block; 
            width: 1em; 
            margin-left: -1em; 
            margin-right: 5px;
          }
        }
      }
    }
  .playlist {
    background: rgba(235, 235, 235, 0.60);
    padding: 20px;

    @media (max-width: 900px) {
      width: 80%;
      max-width: 380px;
      margin-left: initial;
    }

    @media (max-width: 768px) {
      width: 90%;
      max-width: 380px;
      margin-left: initial;
    }
    .player {
      margin: 0 auto;
      border: 1px solid rgba(235, 235, 235, 0.60);
      padding: 50px;

      @media (max-width: 768px) {
        padding: 20px;
      }

      .buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        button {
          background: transparent;
          border: none;
          cursor: pointer;
          svg {
            height: 2rem;
            width: 2rem;
            path {
              fill: $black;
              transition: all .05s;

              &:hover {
                fill: $black;
                opacity: 0.7;
              }
            }
          }
        }
        .play-pause {
          margin: 0 10px;
          svg {
            height: 3rem;
            width: 3rem;
          }
        }
      }
      .timebar {
        height: 30px;
        .bar {
          display: block;
          width: 100%;
          height: 3px !important;
          background: black;
          
        }
      }
    }

    h3 {
      text-align: center;
      margin: 20px 0;
      font-size: 26px;
      text-transform: uppercase;
    }
  }
  }

  #music {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 70px 0;

  @media (max-width: 768px) {
    flex-direction: column;
  }

}



}