

$black: #222;
$white: #fff;
$grey: #f9f9f9;

$error: #d63c35;
$success: #498865;

$mainColour: #98edfb;
$secondaryColour: #f6c20b;
$thirdColour:  #0BC2F6;

@import url("https://use.typekit.net/dmk1wil.css");

$mainFont: soleil, sans-serif;
$secondaryFont: coranto-2, serif;